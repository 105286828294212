svg {
    background-color: #797979;
}

.dropdown-input {
    position: absolute;
    top: 20px;
    left: 5%;
    z-index: 1000;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    width: 10%;
}

.dropdown-options {
    position: absolute;
    top: calc(20px + 40px);
    left: 5%;
    z-index: 1000;
    padding: 0;
    margin: 0;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: #fff;
    list-style-type: none;
    width: 10%;
    max-height: 200px;
    overflow-y: auto;
}


.dropdown-option {
    padding: 5px 10px;
    color: #000000;
}

.dropdown-option:hover {
    background: linear-gradient(to top, lightblue, darkcyan);
}

.tooltip {
    position: absolute;
    text-align: center;
    width: 200px;
    height: auto;
    padding: 10px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 10;
}
