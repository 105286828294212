svg {
  background-color: #343434;
}
body {
  background-color: #343434;
  margin: 0;
  padding: 0;
  overflow: hidden;
}


body::-webkit-scrollbar {
  display: none;
}
